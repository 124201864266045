import { Box, Card as MuiCard, Typography } from '@mui/material'
import PropTypes from 'prop-types'
import { makeStyles } from 'tss-react/mui'
import React from 'react'
import Button from 'src/common/components/button/Button'
import { CARD_STATE, CARD_VARIANT } from 'src/common/components/card/constants'
import { rem } from 'src/common/utils/css'
import ArrowRight from 'src/components/icons/ArrowRight'
import theme, { SHADOW } from 'src/styles/theme'
import ImageCard from 'src/common/components/card/ImageCard'
import { useLinkClickHandler } from 'src/common/utils/hooks/useLinkClickHandler'
import isUrlExternal from 'src/common/utils/js/isUrlExternal'
import { TextButtonExternalLink } from 'src/common/components/externalSiteIcon'

const useStyles = makeStyles()((_theme, props) => {
  const { variant } = props

  let containerHeight

  let imageWidth
  let imageHeight

  let cardFlexDirection = 'column'

  if (variant === CARD_VARIANT.HORIZONTAL) {
    imageWidth = rem(264)
    imageHeight = '100%'

    containerHeight = rem(204)

    cardFlexDirection = 'row'
  } else if (variant === CARD_VARIANT.VERTICAL_WIDE) {
    imageWidth = '100%'
    imageHeight = rem(270)

    containerHeight = rem(450)
  } else if (variant === CARD_VARIANT.FEATURE) {
    imageWidth = '100%'
    imageHeight = rem(414)

    containerHeight = rem(564)
  } else if (variant === CARD_VARIANT.MOBILE) {
    imageWidth = '100%'
    imageHeight = rem(245)

    containerHeight = rem(428)
  } else {
    imageWidth = '100%'
    imageHeight = rem(198)

    containerHeight = rem(420)
  }

  return {
    card: {
      cursor: 'pointer',
      background: theme.palette.presidio.color.NEAR_WHITE,
      borderRadius: rem(4),
      boxShadow: SHADOW.LEVEL_THREE,
      transition: 'box-shadow 0.5s ease-in-out',
      '&: hover': {
        boxShadow: SHADOW.LEVEL_FOUR,
        transition: 'box-shadow 0.5s ease-in-out',
      },
      '@media (hover: none)': {
        '&: hover': {
          boxShadow: SHADOW.LEVEL_THREE,
          transition: 'box-shadow 0.5s ease-in-out',
        },
      },
      display: 'flex',
      flexDirection: cardFlexDirection,
      width: '100%',
      minHeight: containerHeight,
    },
    container1: {
      flexDirection: 'column',
      justifyContent: 'space-between',
      flexGrow: variant === CARD_VARIANT.FEATURE ? 0 : 1,
      padding: `${rem(24)} ${rem(24)} ${rem(27)}`,
      display: 'flex',
    },
    container2: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'flex-start',
      gap: rem(8),
    },
    cardTitle: {
      ...theme.typography.cardTitle,
      fontWeight: 400,
      color: theme.palette.primary.dark,
    },
    description: {
      ...theme.typography.smallBody.default,
    },
    cardImageContainer: {
      position: 'relative',
      overflow: 'hidden',
      minWidth: imageWidth,
      minHeight: imageHeight,
      flexShrink: 0,
      flexGrow: variant === CARD_VARIANT.FEATURE ? 1 : 0,
    },
  }
})

function CardPlace(props) {
  const { cardData, variant, gaTag, collapseMegaMenu = () => {} } = props
  if (!cardData) return null

  const { title, description, image, link } = cardData || {}

  const { classes } = useStyles({ variant })

  const [cardState, setCardState] = React.useState(CARD_STATE.DEFAULT)

  const handleMouseEnter = (e) => {
    setCardState(CARD_STATE.HOVER)
  }

  const handleMouseLeave = (e) => {
    setCardState(CARD_STATE.DEFAULT)
  }

  const handleMouseDown = (e) => {
    setCardState(CARD_STATE.ACTIVE)
  }

  const handleMouseUp = (e) => {
    if ('ontouchstart' in document.documentElement) {
      setCardState(CARD_STATE.DEFAULT)
    } else {
      setCardState(CARD_STATE.HOVER)
    }
  }
  const linkClickHandler = useLinkClickHandler()

  return (
    <MuiCard
      className={classes.card}
      elevation={0}
      component="a"
      href={link?.url}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      onMouseDown={handleMouseDown}
      onMouseUp={handleMouseUp}
      data-ga-location={gaTag}
      onClick={(e) => {
        e.preventDefault()
        collapseMegaMenu(e)
        linkClickHandler(link)
      }}
    >
      <div className={classes.cardImageContainer}>
        <ImageCard
          url={image?.url}
          hover={
            cardState === CARD_STATE.HOVER || cardState === CARD_STATE.ACTIVE
          }
          title={image?.title}
          alt={image?.alt}
        />
      </div>
      <Box className={classes.container1}>
        {(title || description) && (
          <div className={classes.container2}>
            {title && (
              <Typography component="h3" className={classes.cardTitle}>
                {title}
              </Typography>
            )}
            {description && (
              <Typography className={classes.description}>
                {description}
              </Typography>
            )}
          </div>
        )}
        {link && link.title && (
          <Box mt={rem(14)}>
            <Button
              variant="text"
              data-testid="place-card-button"
              tabIndex={-1}
              endIcon={
                isUrlExternal(link?.url) ? (
                  <TextButtonExternalLink />
                ) : (
                  <ArrowRight />
                )
              }
              hover={cardState === CARD_STATE.HOVER}
              active={cardState === CARD_STATE.ACTIVE}
            >
              {link.title}
            </Button>
          </Box>
        )}
      </Box>
    </MuiCard>
  )
}

export default CardPlace

CardPlace.propTypes = {
  cardData: PropTypes.shape({
    title: PropTypes.string,
    link: PropTypes.shape({
      title: PropTypes.string,
      url: PropTypes.string,
      target: PropTypes.string,
    }),
    image: PropTypes.shape({
      alt: PropTypes.string,
      url: PropTypes.string,
    }),
    description: PropTypes.string,
  }),
  variant: PropTypes.oneOf([
    CARD_VARIANT.HORIZONTAL,
    CARD_VARIANT.MOBILE,
    CARD_VARIANT.VERTICAL,
    CARD_VARIANT.VERTICAL_WIDE,
    CARD_VARIANT.FEATURE,
  ]),
  gaTag: PropTypes.string,
}
